.selected {
  color: rgb(81, 237, 60) !important;
  background-color: dodgerblue;
  font-weight: bold;
  font-size: small;
}

.interconsulta{
  color: aqua;
  background-color: brown;
  font-weight: bold;
}

.selectedMedico {
  color: wheat !important;
  background-color: darkblue;
  font-weight: bold;
}

.venta-center{
  text-align: center;
  font-size: small;
}
.venta-center-medium{
  text-align: center;
  font-size: small;
}

.venta-right{
  text-align: right;
  font-size: small;
}

.conSignos {
  background-color: darkseagreen !important;
  color: black;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  #menu-toggle:checked+.nav-menu {
    display: block;
  }
}

.active {
  background-color: rgb(194, 196, 246);
  color: rgb(30, 57, 80) !important;
}

.medicamento{
  font-size: 0.8em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.producto{
  font-size: 0.8em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.producto-head{
  font-size: 0.8em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
}

.form-outline {
  position:relative;
  width:100%
 }

 .login-wrap {
  position: relative;
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-group .submit {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.factura-datos{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size:12px;
}
.factura-datos-bold{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size:12px;
  font-weight: 900;
}

.facturado-true{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size:12px;
  color: red;
}
.facturado-false{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size:12px;
  color: green;
}

.factura-center-medium{
  text-align: center;
  font-size: smaller;
}

.factura-right-medium{
  text-align: right;
  font-size: smaller;
}

.paciente-texto{
  color: darkseagreen;
  font-size: small;
}

.paciente-dato{
  border-bottom: 1px solid darkseagreen;
  text-align: center;
  font-size: small;
  font-family: 'Courier New', Courier, monospace;
  padding-bottom: 0px;
}
.fecha-cita{
  text-align: center; 
  border-bottom: 1px solid darkseagreen;
  padding-bottom: 0px;
}
.alergias{
  border-bottom: 1px solid darkseagreen;
  text-align: center;
  font-size: small;
}
.label-alergias{
  font-size: small;
  font-family: 'Courier New', Courier, monospace;
}

.receta-signos{
  text-align: right;
  color: darkseagreen;
  font-size: small;
}

.receta-signos-datos{
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: small;
}

.datos-footer-medico{
  text-align: center;
  font-size: small;
}

.datos-footer-direccion{
  text-align: center;
  font-size: small;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.diagnostico{
  font-family: 'Courier New', Courier, monospace;
  font-size: small;
  font-weight: bold;
  text-align: left;
}

.datos-footer-whatsapp{
  color: darkseagreen;
  text-align: center;
  font-size: small;
}
.datos-footer-cedula{
  border-top: 1px solid darkseagreen;
  text-align: center;
  font-size: small;
}